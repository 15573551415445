// Colors

.approved {
  background-color: $approved;
  color: $font-color-light;
}

.cancelled {
  background-color: $cancelled;
  color: $font-color-light;
}

.revisions-requested {
  background-color: $revisions-requested;
  color: #000;
}

.in-progress {
  background-color: $revisions-requested;
  color: $font-color;
}

.pending {
  color: $font-color;
  border: 1px solid $font-color;
}

.needs-approval {
  background-color: $needs-approval;
  color: $font-color-light;
}
