// dashboard-filter

$block: 'dashboard-filter';

.#{$block} {
  margin: 5rem auto;
  justify-content: center;
  width: 100%;

  &__item {
    text-align: center;
    margin-right: 1rem;

    &--half {
      max-width: 50%;
    }

    &--third {
      max-width: 33.33%;
    }
  }

  &__title {
    display: block;
    @include function-title;
    color: $font-color;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  &--designer,
  &--project-manager {
    margin-top: 0;
  }

  &--project-manager {
    .#{$block}__item {
      margin: 0 1rem;
    }

    .button--filter {
      width: 18rem;
    }
  }

  &--search {
    margin-top: 0;
    margin-bottom: 3rem;
  }

  // the above is for the container. below is the search form code
  &__search {
    display: flex;
    align-items: flex-start;
    text-align: center;
    &__input {
      padding: 1.5rem 2rem;
      border-radius: 3rem;
      width: 30rem;
      max-width: calc(100% - 1rem);
      margin-right: 1rem;
      border: $border-width--title solid $border-color;
    }

    button {
      margin-top: 2.2rem;
    }
  }

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
    width: calc(100% - 8rem);
    max-width: 60rem;
    margin-left: 0;

    &--project-manager {
      .dashboard-filter__item {
        margin-bottom: 1rem;
        margin-left: 0;
      }
    }
    &__item {
      margin-bottom: 1rem;
    }
    &__search {
      flex-wrap: wrap;
    }

    &__search, &__item {
      width: 100%;
      max-width: 40rem;
      text-align: left;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 767px) {
    margin-left: 0;

    &--account {
      margin-left: 0;
    }

    &__search, &__search__bar {
      width: 100%;
      max-width: 100%;
    }
  }
}
