// Header

$block: 'dashboard-header';

.#{$block} {
  @include block-border;
  display: flex;
  width: 100%;
  background-color: $font-color-light;
  padding: 0 2rem;
  align-items: center;
  border: $border-width solid $border-color;

  &__client-name {
    font-size: 3rem;
    padding: 2rem 2rem 2rem 0;
    border-right: $border-width solid $border-color;
    font-weight: 600;
  }

  &__project-cost {
    margin-left: auto;
    font-size: 2rem;
    color: $font-color;
    font-weight: 600;
  }

  &__progress-bar {
    position: relative;
    top: .2rem;
    margin-left: auto;

    &__title {
      display: block;
      color: $font-color;
      margin-bottom: .7rem;
      text-align: center;
    }

    &__avg {
      position: relative;
      display: block;
      color: $font-color;
      margin-top: .5rem;
      text-align: center;
      font-size: 7px;
      z-index: 5;

      &:before {
        position: absolute;
        left: 50%;
        top: -1.7rem;
        content: ' ';
        width: 2px;
        background-color: $font-color;
        height: 1.2rem;
      }
    }

    &__bar {
      position: relative;
      overflow: hidden;
      width: 30rem;
      height: 1rem;
      border-radius: 1rem;
      border: 1px solid $font-color;

      &__inner {
        position: absolute;
        top: 2px;
        left: 3px;
        width: 10px;
        height: 6px;
        background-color: $font-color;
        border-radius: 5px;
        opacity: .9;

        &--over {
          background-color: red;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      margin-left: 0;
      width: 100%;
    }
  }

  &--project,
  &--new-project,
  &--support,
  &--support--project-manager,
  &--account {
    .#{$block}__client-name {
      padding: 0 2rem .6rem 0;

      .text-button {
        position: relative;
        top: -.2rem;
        display: inline-block;
        margin: .5rem 0 .7rem;
        @include underline-animation-color;
      }

      span {
        position: relative;
        top: -.3rem;
      }
    }

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;

      .#{$block}__client-name {
        border-right: 0;
        border-bottom: $border-width solid $border-color;
        width: 100%;
      }

      .#{$block}__progress-bar {
        padding: 1rem 0;
      }

      .#{$block}__progress-bar__bar {
        width: 100%;
      }
    }
  }

  &--new-project,
  &--support,
  &--support--project-manager,
  &--account{
    margin: 0 auto;
    max-width: 60rem;
  }

  &--support--project-manager {
    margin-left: 0;
  }

  &--account {
    .dashboard-header__client-name {
      padding: 1.5rem 2rem 1rem 0;
    }
  }

  &--no-button {
    .dashboard-header__client-name {
      border-right: none;
      padding: 1.5rem 1rem 1rem;
      text-align: center;
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    .button {
      margin: 1rem 0;
    }
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    &__client-name {
      width: 100%;
      padding: 1rem 0 .7rem;
      border-right: 0;
      border-bottom: $border-width solid $border-color;
      font-size: 2.4rem;
    }
  }
}
