// Project Card

$block: 'project-card';

.#{$block} {
  position: relative;
  @include block-border;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: $font-color-light;
  width: calc(100% - 1rem);
  margin-bottom: 2rem;
  border: $border-width solid $border-color;
  box-sizing: border-box;

  /*&:nth-child(odd) {
    margin-right: 2rem;
  }*/

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2rem;
    border-bottom: $border-width solid $border-color;
    font-weight: 600;

    &--reduce-padding {
      padding: 1rem 2rem;
    }
  }

  &__info {
    position: relative;
    min-height: 11rem;
    min-width: 26.5rem;
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;

    @media screen and (max-width: 1024px) {
      min-height: unset;
      padding-bottom: 2rem;
      width: 100%;
      height: auto;
    }
  }

  &.#{$block}--approved,
  &.#{$block}--needs-approval {
    .#{$block}__info {
      @media screen and (max-width: 1024px) {
        min-height: 0;
        padding-bottom: 2rem;
      }
    }
  }

  &__priority {
    border: 1px solid $font-color;

    &--1 {
      border-color: #ffea08;
      background-color: #ffea08;
      color: #000;
    }

    &--2 {
      border-color: $cancelled;
      background-color: $cancelled;
      color: #fff;
    }
  }

  &__image {
    position: relative;
    max-width: 22rem;
    margin: 1.6rem auto 1.6rem;
    height: 0;
    padding-bottom: 45%;
    width: 100%;
    border-radius: 1rem;

    img {
      position: absolute;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.08);
    }

    @media screen and (max-width: 1200px) {
      max-width: 18.1rem;
    }

    @media screen and (max-width: 1024px) {
      margin-top: 0;
      margin-bottom: 3rem;
      max-width: calc(100% - 3.2rem);
      padding-bottom: 66%;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__new-campaign {
    position: absolute;
    bottom: 1.6rem;
    right: 2rem;
    max-width: 22rem;

    @media screen and (max-width: 1200px) {
      max-width: 18.1rem;
    }

    @media screen and (max-width: 1024px) {
      position: static;
      margin-left: 1.6rem;
      margin-top: 0;
      margin-bottom: 1.2rem;
      width: 100%;
      max-width: 100%;

      .button {
        margin-left: 0;
      }
    }
  }

  &__link {
    @include link-style;
  }

  &__footnotes {
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    width: calc(100% - 4rem);

    @media screen and (max-width: 1024px) {
      position: static;
      margin-top: 2rem;
    }
  }

  &__footnote {
    font-size: 1.2rem;
    font-weight: 300;

    a {
      text-decoration: underline;
    }
  }

  &__title {
    font-size: 1.6rem;
    padding-right: 1rem;
    font-weight: 600;
  }

  &__client-name, &__designer-name {
    font-size: 1.4rem;
    color: $font-color;
    font-weight: 600;
    margin-top: -1rem;
    margin-bottom: 2rem;
    text-decoration: underline;
    display: inline-block;
  }

  &__designer-name {
    margin-left: 1rem;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;

    &:nth-child(odd) {
      margin-right: auto;
    }

    .tag {
      text-align: center;
      line-height: 1.3;
      max-width: 7rem;
    }
  }
}
