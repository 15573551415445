$block: 'modal';

.#{$block} {
  z-index: 20;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(6px);
  background-color: rgba(0,0,0,0.3);

  &__inner {
    width: 100%;
    max-width: 60rem;
    background-color: $font-color-light;
    border: $border-width solid $border-color;
  }

  &__title {
    @include title-style;
  }

  &__container {
    background-color: white;
    width: 50%;
    height: 50%;
    border-radius: 5px;
    overflow: hidden;
  }

  &__top-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 0;
  }

  &__close {
    position: relative;
    top: 0;
    right: 0;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  &__icon {
    font-size: 2rem;
  }
}
