// Nav

$nav: "nav";

.#{$nav} {
  border-bottom: $border-width solid $border-color;
  &__top {
    background-color: $font-color;
    padding: 1rem 0;

    button {
      @include underline-animation-light;
      cursor: pointer;
      color: $font-color-light;
      background: transparent;
      border: none;

      &:not(.last) {
        margin-left: auto;
      }
    }
  }

  &__menu {
    padding: 2rem 0;

    li {
      font-size: 1.4rem;
      font-weight: 400;
      text-transform: uppercase;
      padding: 0 5rem 0 0;

      &.#{$nav}__menu__has-button {
        padding: 0 1.5rem;

        &:last-of-type {
          padding-right: 0;
        }
      }

      &.active a:before {
        width: 100%;
      }

      &.mobile-break {
        width: 100%;
      }

      .tag {
        display: inline-block;
        margin-left: 1rem;
      }
    }

    &__inner {
      align-items: center;
    }

    a:not(.button) {
      @include underline-animation-color;
      font-weight: 600;
    }

    @media screen and (max-width: 1200px) {
      li {
        padding-right: 2.5rem;

        &.#{$nav}__menu__has-button {
          padding-right: 0;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      padding-bottom: 1rem;

      a:not(.button) {
        display: inline-block;
        margin-bottom: 2rem;
        font-size: 1rem;
      }

      li {
        padding-right: 1rem;
      }

      li.#{$nav}__menu__has-button {
        margin-left: 0;
        margin-bottom: 1rem;
        padding-left: 0;
        padding-right: 1.5rem;

        .button {
          padding: 0.75rem 1rem;
        }
      }
    }
  }
}
