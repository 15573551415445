// Account

$block: 'account';

.#{$block} {
  width: 100rem;
  max-width: calc(100% - 8rem);
  margin-top: 2rem;

  &__title {
    @include title-style;
  }

  &__view {
    background-color: $font-color-light;
    width: calc(100% - 36.8rem);
    margin-right: 2rem;
    border: $border-width solid $border-color;
  }

  &__sidebar {
    background-color: $font-color-light;
    width: 30rem;
    border: $border-width solid $border-color;
    box-sizing: border-box;

    ul {
      margin: 2rem 0 0;
      padding: 0;

      li {
        position: relative;
        font-size: 1.4rem;
        font-weight: 400;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid rgba(112, 112, 112, 0.1);

        a {
          width: 100%;
          display: block;
        }

        &.active:before, &:hover::before {
          position: absolute;
          bottom: 0;
          content: ' ';
          width: 100%;
          height: 1px;
          background-color: $font-color;
        }
      }
    }
  }

  input[type=text],
	input[type=url],
  input[type=tel],
  input[type=email] {
    &::placeholder {
      color: $font-color;
    }
  }

  .form {
    margin-left: 0;
    border: none;
  }

  &--full {
    max-width: 100%;
  }

  @media screen and (max-width: 1024px) {
    &__view, &__sidebar {
      width: 100%;
      margin-right: 0;
    }
    &__view {
      order: 2;
    }
    &__sidebar {
      margin-bottom: 2rem;
      order: 1;
    }
  }

  @media screen and (max-width: 767px) {
    max-width: calc(100% - 4rem);

    &__sidebar {

    }

    .dashboard-header__client-name {
      border-bottom: none;
    }
  }
}
