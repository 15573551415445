@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Light.woff2") format("woff2"),
    url("../fonts/Gotham-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Medium.woff2") format("woff2"),
    url("../fonts/Gotham-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"),
    url("../fonts/Gotham-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Ultra.woff2") format("woff2"),
    url("../fonts/Gotham-Ultra.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
