// Spacing
.margin-top-small {
	margin-top: 1rem;
}

.margin-bottom-small {
	margin-bottom: 1rem;
}

.margin-bottom-med {
	margin-bottom: 2rem;
}

.margin-left-small {
	margin-left: 1rem;
}