// Downloads

$block: 'downloads';

.#{$block} {
  &__item {
    display: flex;
    align-items: center;
    margin: 1.5rem;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 600;
    margin-right: auto;
  }

  &__link {
    @include link-style;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 0;
    cursor: pointer;

    button {
      background: none;
      border: none;
      box-shadow: none;
      outline: none;
      cursor: pointer;
      color: $font-color;
    }
  }

  @media screen and (max-width: 600px) {
    &__item {
      flex-wrap: wrap;
    }
    &__title, &__link {
      width: 100%;
    }


    &__link {
      margin-top: 1.5rem;

      button {
        padding-left: 0;
      }
    }
  }
}
