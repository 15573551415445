// Project View

$block: 'project-view';

.#{$block} {
  margin-top: 5rem;

  &__approval {
    margin-bottom: 2rem;

    &__action {
      width: calc(50% - .5rem);


      &--approve {
      }
      &--request-revisions,
      &--download-files,
      &--request-packaging {
        margin-left: 1rem;
      }
      &--request-revisions {
        border-color: red;
        background-color: red;
        color: $font-color-light;
      }

      &--request-packaging {
        background: none;
        color: $font-color;
        border-color: $font-color;
      }

      @media screen and (max-width: 1024px) {
        width: 100%;

        &--request-revisions,
        &--download-files,
        &--request-packaging {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
  }

  &__design {
    width: 55%;



    &__view {
      background-color: $font-color-light;
      border: $border-width solid $border-color;

      &__main {
        img {
          width: 100%;
        }
      }
    }

    &__thumbnails {
      display: flex;
      padding: 0 2rem;
      align-items: flex-start;

      img {
        width: 23%;
        margin: 0 1%;
      }
    }

    &__thumbnail {
      box-sizing: border-box;
      &--active, &:hover {
        border: $border-width solid $font-color;
        @include block-border;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__options {

    }

    &__text {
      margin-top: 2rem;
      font-size: 1.4rem;
    }
  }

  &__sidebar {
    width: calc(45% - 2rem);
    margin-right: 2rem;

    &__details {
      background-color: $font-color-light;
      margin-bottom: 2rem;
      border: $border-width solid $border-color;

      &__description {
        margin-top: 2rem;
        font-size: 1.4rem;
      }
    }

    &__chat {
      background-color: $font-color-light;
      border: $border-width solid $border-color;
    }

    &__tools {
      background-color: $font-color-light;
      margin-bottom: 2rem;
      border: 0.4rem solid #EFDD8D;
    }
  }

  &__title {
    @include title-style;

    &__action {
      padding: 1rem;
      margin-top: -1rem;
      margin-bottom: -.2rem;


    }
  }

  &--in-progress {

  }

  &--new-project {
    margin-top: 2rem;
  }

  @media screen and (max-width: 1024px) {
    &__sidebar, &__design {
      width: 100%;
      margin-right: 0;
    }
    &__design {
      order: 1;
      margin-bottom: 2rem;
    }
    &__sidebar {
      order: 2;
    }
  }
}
