// Typography

html {
  font-size: 62.5%;
}

body {
  font-family: 'Gotham', Verdana, sans-serif;
}

h1, h2, h3, h4, h5, h6, p, ul, li, a {
  margin: 0;
  line-height: 1;
  color: $font-color;
}

ul {
  list-style: none;
}

h1 {

}

h2 {

}

p {
  font-size: 1.6rem;
  line-height: 1.5;
}

a {
  text-decoration: none;

}

.tag {
  padding: .6rem .8rem;
  border-radius: .4rem;
  font-weight: 600;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: 1rem;
}

.text-button {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-weight: 600;
}

label {
  display: block;
  font-size: 1.4rem;
  color: $font-color;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

input[type=text],
input[type=url],
input[type=tel],
input[type=email],
input[type=password],
input[type=name],
select,
textarea {
  width: calc(100% - 2rem);
  margin-bottom: 1rem;
  padding: 1.3rem 1rem;
  //border: 1px solid #E3E8F0;
  //@include block-border;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  border-radius: 5px;
  font-size: 1.2rem;
}

textarea {
  min-height: 6rem;
}

select {
  width: 100%;
  color: rgb(117, 117, 117);
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';

  &::-ms-expand {
    display: none;
  }
}

.select {
  position: relative;

  i {
    position: absolute;
    top: calc(50% - 1.3rem);
    right: 1rem;
    font-size: 2rem;
    color: $font-color;
  }

}

.upload-block {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F3F3F3;
  @include block-border;
  width: 22rem;
  height: 14.4rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: .2s box-shadow ease-in-out;
  letter-spacing: .05em;

  &:hover {
    transition: .2s box-shadow ease-in-out;
    -webkit-box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.26);
  }
}
