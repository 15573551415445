.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 75vh;
  background-color: #1a1a1d; */
}

.container-chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  height: 400px;
  width: 100%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .outerContainer {
    height: 100%;
  }

/*  .container {
    width: 100%;
    height: 100%;
  }*/
}

@media (min-width: 480px) and (max-width: 1200px) {
/*  .container {
    width: 60%;
  }*/
}
