// Buttons

.button {
  @include function-title;
  display: inline-block;
  border-radius: 3rem;
  padding: 1.5rem 2rem;
  transition: .2s ease-in-out;
  cursor: pointer;
  font-weight: 600;

  &--light {
    background-color: transparent;
    color: $font-color;
    border: 1px solid $font-color;

    &:hover {
      transition: .2s ease-in-out;
      background-color: $font-color;
      color: $font-color-light;
    }
  }

  &--dark {
    background-color: $font-color;
    color: $font-color-light;
    border: 1px solid $font-color;

    &:hover {
      transition: .2s ease-in-out;
      background-color: $font-color-light;
      color: $font-color;
    }
  }

	&--red {
    background-color: $cancelled;
    color: $font-color-light;
    border: 1px solid $cancelled;

    &:hover {
      transition: .2s ease-in-out;
      background-color: $font-color-light;
      color: $cancelled;
    }
  }

  &--filter {
    position: relative;
    width: 24rem;
    max-width: 100%;

    i {
      position: absolute;
      right: 2rem;
      top: 1.5rem;
      font-size: 120%;
    }

    &:before {
      position: absolute;
      right: 2rem;
      top: 1.5rem;
      font-size: 120%;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: 'Font Awesome 5 Duotone'; font-weight: 900; content: "\f107";
    }
  }

  &--tag {
    padding: .6rem .8rem;
    border-radius: .4rem;
    font-weight: 600;
    letter-spacing: .1em;
    text-transform: uppercase;
    font-size: 1rem;
  }

  &--margin-right {
    margin-right: 1rem;
  }
}
