// Form

$block: 'form';

.#{$block} {
  max-width: 60rem;
  margin: 2rem auto 0;
  width: 100%;
  background-color: $font-color-light;

  &__instructions {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    &--label {
      padding-left: 1rem;
    }
  }

  &__upload-status {
    padding-left: 1rem;
  }

  button[type=submit] {
    margin-top: 2rem;
  }

  .upload-block {
    margin-bottom: 3rem;
  }

  &--new-project {
    border: $border-width solid $border-color;
  }

  &__disclaimer {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
}
