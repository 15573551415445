.messages {
  padding: 5% 0;
  overflow-y: auto;
  overflow-x: hidden;
  flex: auto;
}

.messages > div {
  overflow-x: hidden;
}

/* TODO - refactor into _chat.scss */
