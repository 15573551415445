@mixin underline-animation-light {
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    width: 0;
    background-color: $font-color-light;
    height: 2px;
    transition: .2s width linear;
    left: 0;
    bottom: -5px;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
}

@mixin underline-animation-color {
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    width: 0;
    background-color: $font-color;
    height: 2px;
    transition: .2s width linear;
    left: 0;
    bottom: -5px;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
}

@mixin block-border {
  border-radius: 1rem;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
}

@mixin function-title {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-weight: 600;
}

@mixin title-style {
  position: relative;
  display: flex;
  align-items: flex-end;
  border-bottom: $border-width solid $border-color;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 600;

  .tag {
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
  }

  span {
    display: inline-block;
    margin-right: auto;
  }
}

@mixin link-style {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;

  i {
    margin-right: 1rem;
    min-width: 2rem;
  }

  &:hover a {
    text-decoration: underline;
  }

  @media screen and (max-width: 1320px) {
    a {
      margin-right: auto;
    }
  }
}
