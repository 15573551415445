// Project Tools

$block: 'project-tools';

.#{$block} {
  margin-top: 2rem;

  &__item {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background: none;
    border: none;
    appearance: none;
    -webkit-appearance: none !important;
    padding: 0;
    color: $font-color;
    cursor: pointer;

    i {
      margin-right: 1rem;
      min-width: 2rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__grid {
    display: flex;
    margin-bottom: 3rem;

  }

  &__column {
    width: calc(50% - 3rem);
    margin: 1rem 1.5rem 3rem;

    > div {
      height: 100%;
      ul {
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        list-style: none;

        li {
          font-size: 1.2rem;
          margin: 1rem;
          list-style: none;
        }
      }
    }
  }

  ul, li {
    list-style: none !important;
  }
}
