// Variables
//$font-color: #4e6835;
$font-color: #31a5cd;
$font-color-light: #fff;
$bg-color: #fff;
$approved: $font-color;
$cancelled: red;
$revisions-requested: #f6f8fb;
$needs-approval: #f6f8fb;
$border-width: .4rem;
$border-width--title: .2rem;
$border-color: #31a5cd;
