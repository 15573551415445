// dashboard-filter

$block: 'project-index';

.#{$block} {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 8rem);
  height: calc(100vh - 30rem);
  overflow-y: scroll;
  overflow-x: auto;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  &__header, &__projects {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  &__header {
    z-index: 20;
    box-shadow: $bg-color -3px 0px 0px;
  }

  &__projects {
    height: calc(100vh - 35rem);
  }

  &__column {
    position: relative;
    max-width: 30rem;
  }

  &__title {
    text-align: center;
    padding-bottom: 2rem;
    font-weight: 600;
    width: 30rem;
    background-color: $bg-color;

    &--mobile {
      display: none;
    }
  }

  &--no-projects {
    overflow: hidden;
  }

  @media screen and (max-width: 1024px) {
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    width: calc(100% - 8rem);
    max-width: 60rem;
    margin-left: 0;

    &__projects {
      flex-wrap: wrap;
      height: auto;
      overflow-y: hidden;
      overflow-x: hidden;
    }

    &__title {
      &--mobile {
        display: block;
        text-align: left;
        width: 100%;
        text-transform: uppercase;
      }
    }

    &__header {
      display: none;
    }

    &__column {
      position: relative;
      max-width: 50rem;
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 4rem);
  }
}
