// Layout

.container {
  display: flex;
  flex-wrap: wrap;
  width: 150rem;
  max-width: calc(100% - 8rem);
  margin: 0 auto;
  padding: 0 4rem;

  @media screen and (max-width: 767px) {
    max-width: calc(100% - 4rem);
    padding: 0 2rem;
  }
}

.main {
  background-color: $bg-color;
  background-size: cover;
  padding: 5rem 0 3rem;
  min-height: calc(100vh - 20.5rem);
}

.block-padding {
  padding: 2rem;
  @include block-border;
}

.push-left {
  margin-left: auto;
}

img {
  display: block;
  max-width: 100%;
}

.mobile-break {
  display: block;
  margin-right: auto;
  @media screen and (min-width: 1025px) {
    display: none;
  }
}
