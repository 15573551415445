// Support

$block: 'support';

.#{$block} {
  max-width: 60rem;
  margin: 2rem auto 0;
  background-color: $font-color-light;
  border: $border-width solid $border-color;

  .chat {
    max-height: calc(100vh - 20rem);
  }

  .project-view__title {
    width: 100%;
  }

  &--project-manager {
    margin-left: 0;
  }
}

$pm: 'pm-support';

.#{$pm} {
  display: flex;
  justify-content: center;

  &__left {
    margin-right: 2rem;
  }

  &__designers {
    background-color: $font-color-light;
    min-width: 24rem;

    ul {
      margin: 2rem 0 0;
      padding: 0;
    }
  }

  &__designer {
    position: relative;
    font-size: 1.4rem;
    font-weight: 400;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1);

    &--unread {
      &:before {
        position: absolute;
        right: 1rem;
        top: .5rem;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: $font-color;
        content: ' ';
      }
    }
  }
}
