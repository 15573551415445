// Chat

$block: "chat";

.#{$block} {
  display: flex;
  flex-direction: column;
  // max-height: calc(100vh - 54rem);
  overflow-y: hidden;
  overflow-x: hidden;
  padding-right: 1rem;

  &__message {
    width: 80%;
    margin-top: 1rem;
    padding-right: 1rem;

    &__text {
      display: block;
      width: calc(100% - 2rem);
      padding: 1rem;
      border: 1px solid #e3e8f0;
      @include block-border;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      font-size: 1.2rem;
    }

    &__username {
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    &--customer {
      margin-left: auto;
      .chat__message__username {
        text-align: right;
        color: #000;
      }
      .chat__message__text {
        color: #000;
      }
    }
    &--designer {
      margin-right: 2rem;
    }
  }

  &__new-message {
    position: relative;
    margin-top: 4rem;

    &__title {
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    &__text {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 1.2rem;
      padding: 1rem;
      border: 1px solid #e3e8f0;
      @include block-border;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background: rgba(0, 0, 0, 0.05);

      span {
        width: calc(100% - 2rem);
        display: inline-block;
        padding-right: 2rem;
        opacity: 0.7;
      }

      .button {
        padding: 1rem 1.5rem;
      }
    }
  }

  &__approval {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    color: $font-color;

    i {
      font-size: 1.6rem;
      margin-right: 0.5rem;
    }
  }
}
